import { useEffect, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { useSocketIO } from "../components/SocketIO";
import { staff } from "@anything-pet/grpc-lib";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";
import { useVendorScope } from "../components/VendorScopeProvider";
import {
    Button,
    Divider,
    Grid,
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    LinearProgress,
} from "@mui/material";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export function ListStaff() {
    const vendor = useVendorScope();

    if (!vendor) {
        throw new Error("Vendor is not defined");
    }

    const { id: vendorId } = vendor;

    const [staffs, setStaffs] = useState<staff.Staff[]>();
    const socket = useSocketIO();

    useEffect(() => {
        async function load() {
            if (vendorId) {
                const service = getStaffAdminServiceProxy(socket);

                const result = await service.getStaffAdmins({
                    vendorId: vendorId,
                });

                setStaffs(result.staff);
            }
        }

        load();
    }, [vendorId]);

    if (!vendorId) {
        return <Navigate to="/user" />;
    }

    if (!staffs) {
        return <LinearProgress />;
    }

    const listStyle = {
        py: 0,
        width: "100%",
        borderRadius: 2,
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.paper",
    };

    return (
        <Box>
            <Typography variant="h4">Staff Members</Typography>
            <Typography variant="body1">
                Manage your staff members.
            </Typography>
            <Box paddingTop={"20px"} paddingBottom={"20px"}>
                <Button
                    variant="contained"
                    href={`staff/new`}
                    startIcon={<AddRoundedIcon />}
                >
                    Add a new staff member
                </Button>
            </Box>

            <List sx={listStyle}>
                {staffs.map((staff, index) => (
                    <Box key={staff.id}>
                        <ListItem component="div">
                            <Grid container spacing={1} alignItems="center">
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    sx={{
                                        textAlign: {
                                            xs: "center",
                                            sm: "left",
                                        },
                                    }}
                                >
                                    <ListItemText
                                        id={`staff-list-item-${staff.id}`}
                                        primary={
                                            <Typography fontWeight="bold">
                                                {staff.firstName} {staff.lastName}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    sx={{ textAlign: "center" }}
                                >
                                    <ListItemText
                                        id={`staff-list-item-${staff.id}`}
                                        primary={staff.position}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    container
                                    justifyContent={{
                                        xs: "center",
                                        sm: "flex-end",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        href={`staff/${staff.id}`}
                                        startIcon={<CreateRoundedIcon />}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </ListItem>
                        {index < staffs.length - 1 && (
                            <Divider component="li" />
                        )}
                    </Box>
                ))}
            </List>
        </Box>
    );
}
