import { LinearProgress } from "@mui/material";
import { useAuthManager } from "./Auth";
import { useVendorScope } from "./VendorScopeProvider";
import { PropsWithChildren } from "react";

/**
 * 
 * @param props 
 * @returns 
 */
export function RequireAuthentication(props: PropsWithChildren) {
    const authService = useAuthManager();
    const vendor = useVendorScope();

    if (! authService) {
        return <LinearProgress />;
    }

    if (! authService.isAuthenticated || authService.user.vendorId !== vendor.id) {
        authService.loginWithRedirect({
            prompt: "login"
        });

        return <LinearProgress />
    }

    return <>{props.children}</>;
}