import { useEffect, useState } from "react";
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Snackbar,
    Alert,
    LinearProgress,
} from "@mui/material";
import { useSocketIO } from "./SocketIO";
import { offering } from "@anything-pet/grpc-lib";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";
import { getOfferingAdminServiceProxy } from "../services/OfferingAdminServiceProxy";

interface SupportedOfferingProps {
    vendorId: string;
    staffId: string;
}

const SupportedOffering: React.FC<SupportedOfferingProps> = ({
    vendorId,
    staffId,
}) => {
    const socket = useSocketIO();
    const [offerings, setOfferings] = useState<offering.Offering[]>();
    const [supportedOfferingIds, setSupportedOfferingIds] = useState<string[]>(
        []
    );
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<
        "success" | "error"
    >("success");

    useEffect(() => {
        async function load() {
            const offeringService = getOfferingAdminServiceProxy(socket);
            const { offering } = await offeringService.getOfferingAdmins({
                vendorId,
            });
            setOfferings(offering);

            const staffService = getStaffAdminServiceProxy(socket);
            const { offeringId } = await staffService.getSupportedOfferings({
                vendorId,
                id: staffId,
            });
            setSupportedOfferingIds(offeringId || []);
        }

        load();
    }, [vendorId, staffId, socket]);

    if (!offerings) {
        return <LinearProgress />;
    }

    const supportService = (offeringId: string) =>
        supportedOfferingIds.some((currId) => currId === offeringId);

    const onSupportServiceChanged = (
        offeringId: string,
        supported: boolean
    ) => {
        setSupportedOfferingIds((prevIds) => {
            if (supported) {
                return [...prevIds, offeringId];
            } else {
                return prevIds.filter((id) => id !== offeringId);
            }
        });
    };

    const onSave = async () => {
        try {
            const staffService = getStaffAdminServiceProxy(socket);
            const { status } = await staffService.setSupportedOfferings({
                vendorId,
                id: staffId,
                offeringId: supportedOfferingIds,
            });
            setSnackbarMessage(`Operation ${status}`);
            setSnackbarSeverity("success");
        } catch (error) {
            setSnackbarMessage("Operation failed");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Service</TableCell>
                        <TableCell>Supported</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {offerings.map((offering) => (
                        <TableRow key={offering.id}>
                            <TableCell>{offering.name}</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={supportService(offering.id)}
                                    onChange={(evt) =>
                                        onSupportServiceChanged(
                                            offering.id,
                                            evt.target.checked
                                        )
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button variant="contained" onClick={onSave} sx={{ mt: 2 }}>
                Save
            </Button>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default SupportedOffering;
