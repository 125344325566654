import { useState, ChangeEvent } from "react";
import { TextField, Button, Grid, Box, Typography } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useVendorScope } from "../components/VendorScopeProvider";
import { useSocketIO } from "../components/SocketIO";
import { staff } from "@anything-pet/grpc-lib";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";

const CreateStaff: React.FC = () => {
    const vendor = useVendorScope();
    const socket = useSocketIO();
    const navigate = useNavigate();

    const [staffData, setStaffData] = useState<Omit<staff.Staff, 'id' | 'vendorId'>>({
        firstName: '',
        lastName: '',
        emailAddress: '',
        position: ''
    });

    if (!vendor) {
        return <Navigate to='/' />;
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setStaffData({
            ...staffData,
            [name]: value,
        });
    };

    const onCreate = async () => {
        const service = getStaffAdminServiceProxy(socket);
        const newStaff: staff.Staff = {
            ...staffData,
            id: "", // Add a temporary id value for type consistency
            vendorId: vendor.id,
        };
        const { staff: createdStaff } = await service.addStaffAdmin({ staff: newStaff });

        if (createdStaff) {
            navigate(`/user/staff/${createdStaff.id}`);
        }
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h4">
                New Staff
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="First Name"
                        name="firstName"
                        value={staffData.firstName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        value={staffData.lastName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email Address"
                        name="emailAddress"
                        value={staffData.emailAddress}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Position"
                        name="position"
                        value={staffData.position}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onCreate}
                        sx={{ mr: 2 }}
                        startIcon={<SaveAltRoundedIcon />}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CreateStaff;
