import { Calendar, OnArgs, TileArgs } from "react-calendar";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import * as tz from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import { useSocketIO } from "./SocketIO";
import { getStaffBookingServiceProxy } from "../services/StaffBookingServiceProxy";
import { staffAdmin, staffBooking } from "@anything-pet/grpc-lib";
import { VendorProfile } from "../services/VendorProfileService";
import "react-calendar/dist/Calendar.css";
import "./StaffBookings.css";
import {
    LinearProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Grid,
} from "@mui/material";

dayjs.extend(tz.default);
dayjs.extend(utc.default);

const getCurrentTZ = () => {
    return dayjs.tz.guess();
};

function StaffDaySchedule(props: {
    vendorId: string;
    staffId: string;
    selectedDate?: Date;
}) {
    const { vendorId, staffId, selectedDate } = props;

    const socket = useSocketIO();
    const [bookings, setBookings] = useState<staffBooking.UserBookingReport[]>();

    useEffect(() => {
        async function load() {
            if (selectedDate) {
                const selectedDayjs = dayjs(selectedDate);
                const fromDateTime = selectedDayjs.startOf("day");
                const endDateTime = selectedDayjs.endOf("day");

                const service = getStaffBookingServiceProxy(socket);

                const { booking: bookings } = await service.getBookings({
                    vendorId: vendorId,
                    staffId: staffId,
                    fromDateTime: fromDateTime.toISOString(),
                    toDateTime: endDateTime.toISOString(),
                });

                setBookings(bookings);
            }
        }

        load();
    }, [selectedDate]);

    if (!selectedDate) {
        return <></>;
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Time</TableCell>
                        <TableCell>Offering</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Pet</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bookings?.map((booking) => (
                        <TableRow key={booking.id}>
                            <TableCell>{`${dayjs(booking.startDateTime).format("HH:mm")} - ${dayjs(booking.endDateTime).format("HH:mm")}`}</TableCell>
                            <TableCell>{booking.offeringName}</TableCell>
                            <TableCell>{booking.userDisplayName}</TableCell>
                            <TableCell>{booking.petName}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function StaffMemberBookings(props: {
    staff: staffAdmin.StaffAdmin;
    vendor: VendorProfile;
}) {
    const { vendor, staff } = props;
    const socket = useSocketIO();
    const [selectedDate, setSelectedDate] = useState<Date>();

    const now = dayjs();

    const [startDate, setStartDate] = useState<Date>(
        now.startOf("month").toDate()
    );
    const [bookings, setBookings] = useState<{
        [date: string]: number | undefined;
    }>();

    useEffect(() => {
        async function load() {
            const startDatejs = dayjs(startDate);
            const fromDateTime = startDatejs.startOf("month");
            const endDateTime = startDatejs.endOf("month");

            const service = getStaffBookingServiceProxy(socket);

            const { booking: bookings } = await service.getBookingCountsByDays({
                vendorId: vendor.id,
                staffId: staff.id,
                fromDateTime: fromDateTime.toISOString(),
                toDateTime: endDateTime.toISOString(),
            });

            const bookingMap = bookings.reduce((result, booking) => {
                result[booking.startDateTime] = booking.bookingCount;
                return result;
            }, {} as { [date: string]: number });

            setBookings(bookingMap);
        }

        load();
    }, [startDate, vendor.id, staff.id, socket]);

    if (!bookings) {
        return <LinearProgress />;
    }

    const onActiveStartDateChange = (args: OnArgs) => {
        if (args.activeStartDate) {
            setStartDate(args.activeStartDate);
        }
    };

    const onSelectDay = (day: Date) => {
        setSelectedDate(day);
    };

    const tileClassName = (args: TileArgs) => {
        // Add class to tiles in month view only
        const dateText = dayjs(args.date).utc().toISOString();

        const count = bookings[dateText] ?? 0;

        if (count > 0) {
            return "hasBookings";
        } else {
            return "hasNoBookings";
        }
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} container justifyContent="center">
                    <Calendar
                        value={startDate}
                        onActiveStartDateChange={onActiveStartDateChange}
                        onClickDay={onSelectDay}
                        tileClassName={tileClassName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StaffDaySchedule
                        vendorId={vendor.id}
                        staffId={staff.id}
                        selectedDate={selectedDate}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
