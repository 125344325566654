import { useEffect, useState, ChangeEvent } from "react";
import { TextField, Button, Grid, Box, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useSocketIO } from "../components/SocketIO";
import { offering } from "@anything-pet/grpc-lib";
import { useNavigate, useParams } from "react-router-dom";
import { getOfferingAdminServiceProxy } from "../services/OfferingAdminServiceProxy";
import { useVendorScope } from "../components/VendorScopeProvider";
import SaveAltRoundedIcon from "@mui/icons-material/SaveAltRounded";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

const UpdateService: React.FC = () => {
    const { offeringId } = useParams();
    const vendor = useVendorScope();

    if (!vendor) {
        throw new Error("Vendor is not defined");
    }

    const { id: vendorId } = vendor;
    const isNew = !offeringId;
    const [offeringData, setOfferingData] = useState<
        Omit<offering.Offering, "id" | "vendorId">
    >({
        name: "",
        duration: 60,
        price: 0,
    });

    const socket = useSocketIO();
    const navigate = useNavigate();

    useEffect(() => {
        async function load() {
            if (vendorId) {
                if (isNew) {
                    setOfferingData({
                        name: "",
                        duration: 60,
                        price: 0,
                    });
                } else {
                    const service = getOfferingAdminServiceProxy(socket);
                    const result = await service.getOfferingAdmin({
                        id: offeringId,
                        vendorId: vendorId,
                    });
                    if (result.offering) {
                        const { id, vendorId, ...offeringParam } =
                            result.offering;
                        setOfferingData(offeringParam);
                    }
                }
            }
        }

        load();
    }, [vendorId, isNew, socket, offeringId]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setOfferingData({
            ...offeringData,
            [name]: name === "duration" ? parseInt(value) : value,
        });
    };

    const handlePriceChange = (values: any) => {
        const { floatValue } = values;
        setOfferingData({ ...offeringData, price: floatValue || 0 });
    };

    const onUpdate = async () => {
        const service = getOfferingAdminServiceProxy(socket);

        if (isNew) {
            const newOffering: offering.Offering = {
                ...offeringData,
                id: "", // Add a temporary id value for type consistency
                vendorId: vendorId,
            };
            await service.addOfferingAdmin({ offering: newOffering });
        } else {
            const updatedOffering: offering.Offering = {
                ...offeringData,
                id: offeringId!,
                vendorId: vendorId,
            };
            await service.updateOfferingAdmin({ offering: updatedOffering });
        }

        navigate(`/${vendor.code}/services`);
    };

    const onDelete = async () => {
        if (offeringId) {
            const service = getOfferingAdminServiceProxy(socket);
            await service.removeOfferingAdmin({
                id: offeringId,
                vendorId: vendorId,
            });
        }

        navigate(`/${vendor.code}/services`);
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h4">
                {isNew ? "New Service" : "Update Service"}
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={offeringData.name}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Duration (minutes)"
                        name="duration"
                        type="number"
                        value={offeringData.duration}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <NumericFormat
                        customInput={TextField}
                        fullWidth
                        label="Price ($)"
                        name="price"
                        value={offeringData.price}
                        thousandSeparator
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={handlePriceChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onUpdate}
                        sx={{ mr: 2 }}
                        startIcon={<SaveAltRoundedIcon />}
                    >
                        Save
                    </Button>
                    {!isNew && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onDelete}
                            startIcon={<DeleteOutlineRoundedIcon />}
                        >
                            Delete
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default UpdateService;
