import { AppState, Auth0ContextInterface, Auth0Provider, AuthorizationParams, RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react"
import Button from '@mui/material/Button';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { LinearProgress } from "@mui/material";
import { PropsWithChildren } from "react"
import { useNavigate } from "react-router-dom";
import { getEnv } from "@anything-pet/common-util";

const authConfig = {
    domain: getEnv("REACT_APP_AUTH0_SITE_DOMAIN"),
    clientId: getEnv('REACT_APP_AUTH0_SITE_CLIENTID'),
    audience: getEnv('REACT_APP_AUTH0_SITE_AUDIENCE')
}

export const LoginScopes = ['openid', 'profile', 'offline_access']


export class AcquireTokenError extends Error {
}

class AuthService {
    constructor(private readonly context: Auth0ContextInterface) {        
    }

    get isAuthenticated() { 
        return this.context.isAuthenticated;
    }

    get user() {
        return {
            userId: this.context.user?.sub,
            name: this.context.user?.name,
            email: this.context.user?.email,
            vendorId: this.context.user?.vendorId
        };
    }

    async acquireTokenSilent(options? : {
        audience?: string,
        scopes?: string[]
    }) : Promise<{ accessToken: string }> {
        const scopes = options?.scopes ? options.scopes.join(' ') : undefined;

        const result = await this.context.getAccessTokenSilently({
            authorizationParams: {
                audience: options?.audience ?? authConfig.audience,
                scope: scopes
            }
        });

        return {
            accessToken: result
        };
    }

    loginWithRedirect(options?: { 
        scopes?: string[], 
        prompt?: AuthorizationParams['prompt'],
        returnTo?: string
    }) : void {
        const scopes = options?.scopes ?? LoginScopes;

        const redirectOpts : RedirectLoginOptions<AppState> = {
            authorizationParams: {
                scope: scopes.join(' '),
                prompt: options?.prompt
            },
            appState: {
                returnTo: options?.returnTo ?? `${window.location.pathname}${window.location.search}`
            }        
        };

        this.context.loginWithRedirect(redirectOpts);
    }

    logout() : void {
        this.context.logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        });
    }

    signUp(options?: { scopes?: string[] })  : void {
        const scopes = options?.scopes ?? LoginScopes;

        this.context.loginWithRedirect({
            authorizationParams: {
                scope: scopes.join(' '),
                screen_hint: 'signup'                
            },
            appState: {
                returnTo: `${window.location.pathname}${window.location.search}`
            }
        });
    }
}

export function useAuthManager() {
    const auth0 = useAuth0();

    return (auth0.isLoading) ? undefined : new AuthService(auth0);
}

/**
 * 
 */
export function useAuthUser() {
    const { user, isAuthenticated } = useAuth0();

    return (isAuthenticated && user) ? { userId: user.sub } : null;
}

export function LoginCallback() {
    return <LinearProgress />;
}

/**
 *
 * @returns
 */
export function LoginButton(props: { loginScopes?: string[] }) {
    const authService = useAuthManager();

    const disabled = (!authService);

    const { loginScopes, ...otherProps } = props;

    return (
        <Button {...otherProps} startIcon={<LoginRoundedIcon />} variant="contained" color="primary" sx={{ mt: 2, mr: 1 }} disabled={disabled} 
            onClick={() => authService?.loginWithRedirect({ prompt: 'login' })}
        >
            Login
        </Button>
    );    
}

/**
 *
 * @param props
 * @returns
 */
export function SignUpButton(props: { loginScopes?: string[] }) {
    const authService = useAuthManager();

    const disabled = (!authService);

    const { loginScopes, ...otherProps } = props;

    return (
        <Button {...otherProps} startIcon={<VpnKeyIcon />} variant="contained" color="primary" sx={{ mt: 2, mr: 1 }} disabled={disabled} 
            onClick={() => authService?.signUp()}
        >
            Sign Up
        </Button>
    );    
}

/**
 *
 * @returns
 */
export function LogoutButton() {
    const navigate = useNavigate();

    return (
        <Button startIcon={<LogoutRoundedIcon />} variant="contained" color="primary" sx={{ mt: 2, mr: 1 }} 
            onClick={() => { navigate('/logout') }}
            >Logout</Button>
    );
}

/**
 * 
 * @param props 
 * @returns 
 */
export function AuthenticatedTemplate(props : PropsWithChildren) {
    const authService = useAuthManager();

    if (! authService) {
        return <LinearProgress />
    }

    return (authService.isAuthenticated) ? <>{props.children}</> : null;
}

/**
 * 
 * @param props 
 * @returns 
 */
export function UnauthenticatedTemplate(props : PropsWithChildren) {
    const authService = useAuthManager();

    if (! authService) {
        return <LinearProgress />
    }

    return (! authService.isAuthenticated) ? <>{props.children}</> : null;
}

/**
 * 
 * @param props 
 * @returns 
 */
export function AuthProvider(props : PropsWithChildren & { vendorId?: string}) {
    const navigate = useNavigate();

    const onRedirectCallback = (appState?: AppState) => {
        console.log(`Return to: ${appState?.returnTo}`)
        navigate(appState?.returnTo || '/', { replace: true });
      };
      
    return (
        <Auth0Provider 
            domain={authConfig.domain}
            clientId={authConfig.clientId}
            authorizationParams={{ 
                audience: authConfig.audience,
                redirect_uri: `${window.location.origin}/callback`,
                vendorId: props.vendorId
            }}
            onRedirectCallback={onRedirectCallback}
            >
            {props.children}
        </Auth0Provider>
    )
}