import { PropsWithChildren, useContext, createContext, useEffect, useState } from "react";
import { useAuthManager } from "./Auth";
import { LinearProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { VendorProfile, getVendorProfileService } from "../services/VendorProfileService";
import { NotFound } from "../pages/NotFound";

const vendorScopeContext = createContext<VendorProfile | undefined>(undefined);

/**
 * Returns the authencicated vendor scope i.e The vendor which current user is authorized to access
 * @returns 
 */
export function useVendorScope() {
    const vendor = useContext(vendorScopeContext);

    if (! vendor) {
        throw new Error('Vendor does not exist');
    }

    return vendor;
}

/**
 * 
 * @param props 
 */
export function VendorScopeProvider(props: PropsWithChildren) {
    const { vendorCode } = useParams();
    const [ vendor, setVendor ] = useState<VendorProfile | null>();

    useEffect(() => {
        (async () => {
            if (vendorCode) {
                const service = getVendorProfileService();
                const resultVendor = await service.getVendorProfileByCode(vendorCode);

                if (resultVendor) {
                    setVendor(resultVendor);
                } else {
                    setVendor(null);
                }
            }
        })();
    }, []);

    if (vendor === undefined) {
        return <LinearProgress />;
    }

    if (! vendor) {
        return <NotFound />
    }

    return (
        <vendorScopeContext.Provider value={vendor}>
            {props.children}
        </vendorScopeContext.Provider>
    )
}