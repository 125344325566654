import { AuthenticatedTemplate, UnauthenticatedTemplate } from "../components/Auth";
import { SocketIOProvider } from "../components/SocketIO";
import { Route, Routes } from "react-router-dom";
import { VendorScopeProvider, useVendorScope } from "../components/VendorScopeProvider";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "../components/Theme";
import NavBar from "../components/NavBar";
import VendorAdminDrawer from "../components/VendorAdminDrawer";
import { StaffHome } from "./StaffHome";
import { ListServices } from "./ListServices"
import UpdateService from "./UpdateService";
import EditProfile from "./EditProfile";
import MainContent from "../components/MainContent";
import { ListStaff } from "./ListStaff";
import CreateStaff from "./CreateStaff";

export function Home() {
    return (
        <SocketIOProvider>
            <ThemeProvider theme={Theme}>
                <Box sx={{ display: "flex" }}>
                    <CssBaseline />
                    <NavBar />
                    <VendorAdminDrawer />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Toolbar />
                        <Box key={"content"}>
                            <Routes>
                                <Route index element={<MainContent />} />
                                <Route
                                    path="profile"
                                    element={<EditProfile />}
                                />
                                <Route
                                    path="staff"
                                    element={<ListStaff />}
                                />
                                <Route
                                    path="staff/new"
                                    element={<CreateStaff />}
                                />
                                <Route
                                    path="staff/:staffId/*"
                                    element={<StaffHome />}
                                />
                                <Route
                                    path="services"
                                    element={<ListServices />}
                                />
                                <Route
                                    path="services/new"
                                    element={<UpdateService />}
                                />
                                <Route
                                    path="services/:offeringId"
                                    element={<UpdateService />}
                                />
                            </Routes>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        </SocketIOProvider>
    );
}
