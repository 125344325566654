const serviceUrl = process.env.REACT_APP_SERVICE_URL ?? window.location.origin;

export type VendorProfile = {
    id : string,
    name: string,
    code: string
}

/**
 * 
 */
export interface VendorProfileService {
    /**
     * Resolve a vendor short name to vendor id
     * @param name 
     */
    getVendorProfileByCode(code: string) : Promise<VendorProfile>;
}

/**
 * 
 * @param serviecUrl 
 * @returns 
 */
export function getVendorProfileService() : VendorProfileService {
    return new BffVendorService(serviceUrl);
}


/**
 * 
 */
class BffVendorService implements VendorProfileService {
    
    /**
     *
     */
    constructor(private _serviceUrl: string) {        
    }

    /**
     * 
     * @param shortName 
     */
    async getVendorProfileByCode(shortName: string): Promise<VendorProfile> {
        const response = await fetch(`${this._serviceUrl}/api/vendor?code=${shortName}`,  {
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
             }
        });

        const result = (await response.json()) as { vendor: VendorProfile };

        return result.vendor;
    }

}