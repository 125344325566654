import React from "react";
import {
    AppBar,
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import TodayIcon from "@mui/icons-material/Today";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { getEnv } from "@anything-pet/common-util";
import logo from "../resources/Logo160x60.png";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAuthManager } from "./Auth";
import { useVendorScope } from "./VendorScopeProvider";

const NavBar: React.FC = () => {
    const authService = useAuthManager();

    const consumerSiteUrl: string = "https://localhost:3000";
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();    

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMyBookingsClick = () => {
        window.location.href = consumerSiteUrl + "/user";
    };

    const handleUserProfileClick = () => {
        window.location.href = consumerSiteUrl + "/user/profile";
    };

    const handleLogoutClick = () => {
        navigate("/logout"); // Navigates to the Logout page
    };

    const handleLoginClick = () => {
        authService?.loginWithRedirect();
    };

    const handleSignupClick = () => {
        authService?.signUp();
    };

    const open = Boolean(anchorEl);
    return (
        <AppBar
            position="fixed"
            color="default"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Toolbar>
                <Box style={{ flexGrow: 1 }} padding={"10px"}>
                    <img src={logo} height={"50px"} />
                </Box>
                <AuthenticatedTemplate>
                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&::before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 20,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform:
                                            "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                            }}
                        >
                            {/* <MenuItem onClick={handleMyBookingsClick}>
                                <ListItemIcon>
                                    <TodayIcon />
                                </ListItemIcon>
                                <ListItemText primary="My Bookings" />
                            </MenuItem>
                            <MenuItem onClick={handleUserProfileClick}>
                                <ListItemIcon>
                                    <AccountBoxIcon />
                                </ListItemIcon>
                                <ListItemText primary="My Profile" />
                            </MenuItem>
                            <Divider component="li" /> */}
                            <MenuItem onClick={handleLogoutClick}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </MenuItem>
                        </Menu>
                    </Box>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "visible",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    "&::before": {
                                        content: '""',
                                        display: "block",
                                        position: "absolute",
                                        top: 0,
                                        right: 20,
                                        width: 10,
                                        height: 10,
                                        bgcolor: "background.paper",
                                        transform:
                                            "translateY(-50%) rotate(45deg)",
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                            }}
                        >
                            <MenuItem onClick={handleLoginClick}>
                                <ListItemIcon>
                                    <LoginIcon />
                                </ListItemIcon>
                                <ListItemText primary="Login" />
                            </MenuItem>
                            <MenuItem onClick={handleSignupClick}>
                                <ListItemIcon>
                                    <VpnKeyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sign up" />
                            </MenuItem>
                        </Menu>
                    </Box>
                </UnauthenticatedTemplate>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
