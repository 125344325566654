import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSocketIO } from "../components/SocketIO"
import { offering } from "@anything-pet/grpc-lib";
import { Link } from "react-router-dom";
import { getOfferingAdminServiceProxy } from "../services/OfferingAdminServiceProxy";
import { useVendorScope } from "../components/VendorScopeProvider";

export function OfferingList() {
    const vendor = useVendorScope();

    const { id: vendorId } = vendor;

    const [offerings, setOfferings] = useState<offering.Offering[]>();
    const socket = useSocketIO();

    useEffect(() => {
        async function load() {
            if (vendorId) {
                const service = getOfferingAdminServiceProxy(socket);

                const result = await service.getOfferingAdmins({ vendorId: vendorId });

                setOfferings(result.offering);
            }
        }

        load();
    }, [vendorId]);

    if (! offerings) {
        return <h1>Loading...</h1>;
    }

    return (<>
        <h1>Offering list</h1>
        <Link to={`new`}>New</Link>
        <ul>
            {offerings.map(offering => {
                return (
                    <li key={offering.id}><Link to={`${offering.id}`}>{offering.name}</Link></li>
                );
            })}
        </ul>
    </>);
}