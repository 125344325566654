import { useEffect, useState } from "react";
import { useSocketIO } from "../components/SocketIO"
import { getVendorAdminServiceProxy } from "../services/VendorAdminServiceProxy";
import { vendorAdmin } from "@anything-pet/grpc-lib";
import { useNavigate } from "react-router-dom";
import { useVendorScope } from "../components/VendorScopeProvider";
import { LinearProgress } from "@mui/material";

export function UpdateVendor() {
    const [vendorJson, setVendorJson] = useState<string>();
    const socket = useSocketIO();
    const navigate = useNavigate();
    
    const vendor = useVendorScope();
    const vendorId = vendor.id;

    useEffect(() => {
        async function load() {
            const service = getVendorAdminServiceProxy(socket);

            const result = await service.getVendorAdmin({ id: vendorId});

            if (result.vendor) {
                const { id, ...vendorParam } = result.vendor;

                setVendorJson(JSON.stringify(vendorParam, undefined, 2));
            }
        }

        load();
    }, []);

    if (! vendorJson) {
        return <LinearProgress />
    }

    const onUpdate = async () => {
        const vendor : vendorAdmin.VendorAdmin = JSON.parse(vendorJson);

        const service = getVendorAdminServiceProxy(socket);

        await service.updateVendorAdmin({ 
            vendor: {
                ...vendor,
                id: vendorId
            }
        });

        navigate(`/${vendor.code}`);
    }

    return (<>
        <h1>Update</h1>
        <div>
            <textarea rows={50} cols={100} value={vendorJson} onChange={evt => setVendorJson(evt.target.value)} />
            <div>
                <button onClick={onUpdate}>Save</button>
            </div>            
        </div>
    </>);
}