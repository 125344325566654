import { useState, ChangeEvent } from "react";
import { Box, Typography, Tabs, Tab, Snackbar, Alert } from "@mui/material";
import { useSocketIO } from "./SocketIO";
import { staffAdmin } from "@anything-pet/grpc-lib";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";
import { VendorProfile } from "../services/VendorProfileService";
import SupportedOffering from "./SupportedOffering";
import StaffInfo from "./StaffInfo";
import { StaffMemberBookings } from "./StaffMemberBookings";

function getStaffInput(staff?: staffAdmin.StaffAdmin): staffAdmin.StaffAdmin {
    if (staff) {
        return staff;
    }

    const newStaff: staffAdmin.StaffAdmin = {
        id: "",
        vendorId: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        position: "",
    };

    return newStaff;
}

export function UpdateStaffMember(props: {
    staff: staffAdmin.StaffAdmin;
    vendor: VendorProfile;
}) {
    const { vendor } = props;
    const { id: vendorId } = vendor;

    const socket = useSocketIO();

    const [staffData, setStaffData] = useState<staffAdmin.StaffAdmin>(
        getStaffInput(props.staff)
    );
    const [tabIndex, setTabIndex] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<
        "success" | "error"
    >("success");

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setStaffData({ ...staffData, [name]: value });
    };

    const onUpdate = async () => {
        const service = getStaffAdminServiceProxy(socket);
        try {
            const { status } = await service.updateStaffAdmin({
                staff: {
                    ...staffData,
                    id: staffData.id,
                    vendorId: vendorId,
                },
            });
            setSnackbarMessage(`Operation completed: ${status}`);
            setSnackbarSeverity("success");
        } catch (error) {
            setSnackbarMessage("Operation failed");
            setSnackbarSeverity("error");
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h4">{`${staffData.firstName} ${staffData.lastName}`}</Typography>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="Staff Member Tabs"
            >
                <Tab label="Staff Info" />
                <Tab label="Services" />
                <Tab label="Schedule" />
            </Tabs>
            {tabIndex === 0 && (
                <StaffInfo
                    staffData={staffData}
                    handleInputChange={handleInputChange}
                    onUpdate={onUpdate}
                />
            )}
            {tabIndex === 1 && (
                <Box sx={{ mt: 2 }}>
                    <SupportedOffering
                        vendorId={vendorId}
                        staffId={props.staff.id}
                    />
                </Box>
            )}
            {tabIndex === 2 && (
                <Box sx={{ mt: 2 }}>
                    <StaffMemberBookings staff={props.staff} vendor={props.vendor} />
                </Box>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
