import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { useVendorScope } from "../components/VendorScopeProvider";
import { UpdateStaff } from "../components/UpdateStaff";
import { useEffect, useState } from "react";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";
import { useSocketIO } from "../components/SocketIO";
import { staffAdmin } from "@anything-pet/grpc-lib";
import { StaffBookings } from "../components/StaffBookings";
import { UpdateStaffMember } from "../components/UpdateStaffMember";
import { LinearProgress } from "@mui/material";

export function StaffHome() {
    const { staffId } = useParams()
    const vendor = useVendorScope();
    const socket = useSocketIO();
    const [staff, setStaff] = useState<staffAdmin.StaffAdmin>();

    useEffect(() => {
        async function load() {
            if (staffId) {
                const service = getStaffAdminServiceProxy(socket);

                const result = await service.getStaffAdmin({ id: staffId, vendorId: vendor.id});

                if (result.staff) {
                    const { id, vendorId, ...staffParam } = result.staff;

                    setStaff(result.staff);
                }
            }
        }

        load();
    }, []);
    
    if (! staffId) {
        return <Navigate to={ `/${vendor.code}` } />
    }

    if (! staff) {
        return <LinearProgress />
    }
    
    return (
    <Routes>
        <Route path='schedule' element={<StaffBookings staff={staff} vendor={vendor} />} />
        <Route path="*" element={<UpdateStaffMember staff={staff} vendor={vendor} />} />
    </Routes>
    );
}