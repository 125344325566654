import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
} from "@mui/material";

import BuildIcon from "@mui/icons-material/Build";
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import PetsRoundedIcon from '@mui/icons-material/PetsRounded';
import { useVendorScope } from "./VendorScopeProvider";

const drawerWidth = 200;

const VendorAdminDrawer: React.FC = () => {
    const vendor = useVendorScope();

    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: "border-box",
                },
            }}
        >
            <Toolbar />
            <Box paddingTop={"10px"}>
                <List>
                    <ListItem key="Schedule" disablePadding>
                        <ListItemButton
                            component={Link}
                            to={`/${vendor.code}`}
                            selected={currentPath === `/${vendor.code}`}
                        >
                            <ListItemIcon>
                                <CalendarMonthRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Schedule" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="Profile" disablePadding>
                        <ListItemButton
                            component={Link}
                            to={`/${vendor.code}/profile`}
                            selected={currentPath === `/${vendor.code}/profile`}
                        >
                            <ListItemIcon>
                                <HomeWorkRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Profile" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="Services" disablePadding>
                        <ListItemButton
                            component={Link}
                            to={`/${vendor.code}/services`}
                            selected={currentPath === `/${vendor.code}/services`}
                        >
                            <ListItemIcon>
                                <PetsRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Services" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="Staff" disablePadding>
                        <ListItemButton
                            component={Link}
                            to={`/${vendor.code}/staff`}
                            selected={currentPath === `/${vendor.code}/staff`}
                        >
                            <ListItemIcon>
                                <PeopleAltRoundedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Staff" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
};

export default VendorAdminDrawer;
