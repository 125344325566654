import { LinearProgress } from "@mui/material";
import { useEffect } from "react";

/**
 * 
 * @returns 
 */
export function AnonymousHome() {
  useEffect(() => {
    window.location.href = "https://www.anything-pet.com";
  });

  return (
    <LinearProgress />
  );
}
