import "./App.css";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "./pages/NotFound";
import { TestHome } from "./pages/TestHome"; // Test prototype
import { Home } from "./pages/Home";
import { VendorScopeProvider, useVendorScope } from "./components/VendorScopeProvider";
import { AuthLogoutPage } from "./pages/LogoutPage";
import { AnonymousHome } from "./pages/AnonymousHome";
import { AuthProvider } from "./components/Auth";
import { RequireAuthentication } from "./components/RequireAuthentication";
import { PropsWithChildren } from "react";
import { AuthCallback } from "./pages/AuthCallback";

const link = document.createElement("link");
link.href =
    "https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap";
link.rel = "stylesheet";
document.head.appendChild(link);


function VendorScopeAuthProvider(props: PropsWithChildren) {
    const vendor = useVendorScope();

    return (
    <AuthProvider vendorId={vendor.id}>
        {props.children}
    </AuthProvider>
    );
}


function TestVendorHome() {
    return (
        <VendorScopeProvider>
            <VendorScopeAuthProvider>
                <RequireAuthentication>
                    <TestHome />
                </RequireAuthentication>
            </VendorScopeAuthProvider>
        </VendorScopeProvider>
    );
}

function VendorHome() {
    return (
        <VendorScopeProvider>
            <VendorScopeAuthProvider>
                <RequireAuthentication>
                    <Home />
                </RequireAuthentication>
            </VendorScopeAuthProvider>
        </VendorScopeProvider>
    )
}

function App() {
    return (
        <Routes>
            <Route index element={<AnonymousHome />} />
            <Route path="/callback" element={<AuthCallback />} />
            <Route path="/testhome/:vendorCode/*" element={<TestVendorHome />} />
            <Route path="/logout" element={<AuthLogoutPage />} />
            <Route path="/:vendorCode/*" element={<VendorHome />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default App;
