import { AuthenticatedTemplate, LogoutButton, UnauthenticatedTemplate } from "../components/Auth"
import { SocketIOProvider } from "../components/SocketIO";
import { Link, Route, Routes } from "react-router-dom";
import { useVendorScope } from "../components/VendorScopeProvider";
import { StaffList } from "./StaffList";
import { UpdateStaff } from "../components/UpdateStaff";
import { OfferingList } from "./OfferingList";
import { UpdateOffering } from "./UpdateOffering";
import { UpdateVendor } from "./UpdateVendor";
import { StaffBookings } from "../components/StaffBookings";
import { StaffHome } from "./StaffHome";
import { NewStaff } from "./NewStaff";

const apiScopes = ['https://anythingpet.onmicrosoft.com/vendor-admin-api/VendorAdminAPI.Access'];

function UserMain() {
    return (<>
        <p>
            <Link to='vendor'>Update vendor</Link>
        </p>
        <p>
            <Link to='staff'>Staffs</Link>
        </p>
        <p>
            <Link to='services'>Services</Link>
        </p>
    </>)
}

export function TestHome() {
    const vendor = useVendorScope();

    if (! vendor) {
        throw new Error('Vendor is unknown')
    }

    return (<>
        <UnauthenticatedTemplate>
            Access is denied            
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
            <SocketIOProvider scope={apiScopes}>
            <>
                <LogoutButton />
                <Routes>
                    <Route index element={ <UserMain /> } />
                    <Route path="vendor" element={<UpdateVendor />} />
                    <Route path="staff" element={<StaffList />} />
                    <Route path="staff/new" element={<NewStaff />} />
                    <Route path="staff/:staffId/*" element={<StaffHome />} />
                    <Route path="services" element={<OfferingList />} />
                    <Route path="services/new" element={<UpdateOffering />} />
                    <Route path="services/:offeringId" element={<UpdateOffering />} />
                </Routes>
            </>
            </SocketIOProvider>        
        </AuthenticatedTemplate>
        </>
    );
}