import React, { ChangeEvent } from "react";
import { TextField, Button, Grid } from "@mui/material";
import { staffAdmin } from "@anything-pet/grpc-lib";

interface StaffInfoProps {
    staffData: staffAdmin.StaffAdmin;
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onUpdate: () => void;
}

const StaffInfo: React.FC<StaffInfoProps> = ({
    staffData,
    handleInputChange,
    onUpdate,
}) => {
    return (
        <Grid container spacing={2} direction="column" sx={{ mt: 2 }}>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={staffData.firstName}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={staffData.lastName}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Email Address"
                    name="emailAddress"
                    value={staffData.emailAddress}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    label="Position"
                    name="position"
                    value={staffData.position}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onUpdate}
                    sx={{ mr: 2 }}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
    );
};

export default StaffInfo;
