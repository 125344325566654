import { LinearProgress } from '@mui/material';
import { AuthProvider } from '../components/Auth';


export function AuthCallback() {
    return (
    <AuthProvider>
        <LinearProgress />
    </AuthProvider>
    );
}
