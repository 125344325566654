import { Navigate, useParams } from "react-router-dom";
import { Calendar, OnArgs, TileArgs } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './StaffBookings.css';
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import * as tz from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import { useSocketIO } from "./SocketIO";
import { getStaffBookingServiceProxy } from "../services/StaffBookingServiceProxy";
import { staffAdmin, staffBooking } from "@anything-pet/grpc-lib";
import { useVendorScope } from "./VendorScopeProvider";
import { VendorProfile } from "../services/VendorProfileService";

dayjs.extend(tz.default);
dayjs.extend(utc.default);


const getCurrentTZ = () => {
  return dayjs.tz.guess()
}

function StaffDaySchedule(props: { vendorId: string, staffId: string, selectedDate?: Date }) {
  const { vendorId, staffId, selectedDate } = props;

  const socket = useSocketIO();
  const [bookings, setBookings] = useState<staffBooking.UserBookingReport[]>();

  useEffect(() => {
    async function load() {
      if (selectedDate) {
        const selectedDayjs = dayjs(selectedDate);
        const fromDateTime = selectedDayjs.startOf('day');
        const endDateTime = selectedDayjs.endOf('day');

        const service = getStaffBookingServiceProxy(socket);

        const { booking: bookings } = await service.getBookings({
          vendorId: vendorId,
          staffId: staffId,
          fromDateTime: fromDateTime.toISOString(),
          toDateTime: endDateTime.toISOString()
        });

        setBookings(bookings);
      }
    }

    load();

  }, [selectedDate]);

  if (!selectedDate) {
    return <></>;
  }

  return (
    <div>
      <h3>{dayjs(selectedDate).format('YYYY-MM-DD')} [{getCurrentTZ()}]</h3>
      <table>
        <thead>
          <tr><th>Time</th><th>Offering</th><th>Customer</th><th>Pet</th></tr>
        </thead>
        <tbody>
          {bookings?.map(booking => {
            return (
              <tr key={booking.id}>
                <td>{`${dayjs(booking.startDateTime).format("HH:mm")} - ${dayjs(booking.endDateTime).format("HH:mm")}`}</td>
                <td>{booking.offeringName}</td>
                <td>{booking.userDisplayName}</td>
                <td>{booking.petName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

export function StaffBookings(props: { staff: staffAdmin.StaffAdmin, vendor: { id: string } }) {
  const { vendor, staff } = props;
  const socket = useSocketIO();
  const [selectedDate, setSelectedDate] = useState<Date>();

  const now = dayjs();

  const [startDate, setStartDate] = useState<Date>(now.startOf('month').toDate());
  const [bookings, setBookings] = useState<{ [date: string]: number | undefined }>();

  useEffect(() => {
    async function load() {
      const startDatejs = dayjs(startDate);
      const fromDateTime = startDatejs.startOf('month');
      const endDateTime = startDatejs.endOf('month');

      const service = getStaffBookingServiceProxy(socket);

      const { booking: bookings } = await service.getBookingCountsByDays({
        vendorId: vendor.id,
        staffId: staff.id,
        fromDateTime: fromDateTime.toISOString(),
        toDateTime: endDateTime.toISOString()
      });

      const bookingMap = bookings.reduce((result, booking) => {
        result[booking.startDateTime] = booking.bookingCount;
        return result;
      }, {} as { [date: string]: number });

      setBookings(bookingMap);
    }

    load();

  }, []);

  if (!bookings) {
    return <h1>Loading ...</h1>
  }

  const onActiveStartDateChange = (args: OnArgs) => {
    if (args.activeStartDate) {
      setStartDate(args.activeStartDate);
    }
  }

  const onSelectDay = (day: Date) => {
    setSelectedDate(day);
  }

  const tileClassName = (args: TileArgs) => {
    // Add class to tiles in month view only
    const dateText = dayjs(args.date).utc().toISOString();

    const count = bookings[dateText] ?? 0;
    console.log(`${dateText} - ${count}`)

    if (count > 0) {
      return 'hasBookings';
    } else {
      return 'hasNoBookings';
    }
  }

  return <>
    <h1>{staff.lastName}, {staff.firstName}</h1>
    <div>
      <Calendar
        value={startDate}
        onActiveStartDateChange={onActiveStartDateChange}
        onClickDay={onSelectDay}
        tileClassName={tileClassName}
      />
      <StaffDaySchedule vendorId={vendor.id} staffId={staff.id} selectedDate={selectedDate} />
    </div>
  </>;
}