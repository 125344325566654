import { staffBooking } from '@anything-pet/grpc-lib'
import { Socket } from 'socket.io-client';
import { emitEvent } from '../components/SocketIO';


/**
 * 
 * @param socket 
 * @returns 
 */
export function getStaffBookingServiceProxy(socket : Socket) : staffBooking.StaffBookingService {
    return new BffStaffBookingServiceProxy(socket);
}

/**
 * 
 */
class BffStaffBookingServiceProxy implements staffBooking.StaffBookingService {
    /**
     * 
     * @param socket 
     */
    constructor(private socket : Socket) { }

    /**
     * 
     * @param request 
     */
    getBookingCountsByDays(request: staffBooking.GetBookingCountsByDaysRequest): Promise<staffBooking.GetBookingCountsByDaysResponse> {
        return emitEvent<staffBooking.GetBookingCountsByDaysResponse, staffBooking.GetBookingCountsByDaysRequest>(
            this.socket, "staffBooking:getBookingCountsByDays", request
            );    }

    /**
     * 
     */
    getBookings(request: staffBooking.GetBookingsRequest): Promise<staffBooking.GetBookingsResponse> {
        return emitEvent<staffBooking.GetBookingsResponse, staffBooking.GetBookingsRequest>(
            this.socket, "staffBooking:getBookings", request
            );
    }
}