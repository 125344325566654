import { useEffect, useState } from "react";
import { useSocketIO } from "../components/SocketIO"
import { offering } from "@anything-pet/grpc-lib";
import { useNavigate, useParams } from "react-router-dom";
import { getOfferingAdminServiceProxy } from "../services/OfferingAdminServiceProxy";
import { useVendorScope } from "../components/VendorScopeProvider";


export function UpdateOffering() {
    const { offeringId } = useParams()

    const vendor = useVendorScope();

    if (! vendor) {
        throw new Error('Vendor is not defined');
    }

    const { id: vendorId } = vendor;

    const isNew = ! offeringId;

    const [offeringJson, setOfferingJson] = useState<string>();

    const socket = useSocketIO();
    const navigate = useNavigate();

    useEffect(() => {
        async function load() {
            if (vendorId) {
                if (isNew) {
                    const newOffering : Omit<offering.Offering, 'id' |'vendorId'> = {
                        name: '',
                        duration: 60,
                        price: 0
                    }
            
                    setOfferingJson(JSON.stringify(newOffering, undefined, 2));
                } else {
                    const service = getOfferingAdminServiceProxy(socket);

                    const result = await service.getOfferingAdmin({ id: offeringId, vendorId: vendorId});

                    if (result.offering) {
                        //  Get rid of id and vendorId
                        const {id, vendorId, ...offeringParam } = result.offering;

                        setOfferingJson(JSON.stringify(offeringParam, undefined, 2));
                    }
                }
            }
        }

        load();
    }, []);

    if (! offeringJson) {
        return <h1>Loading...</h1>
    }

    const onUpdate = async () => {
        const offering : offering.Offering = JSON.parse(offeringJson);

        const service = getOfferingAdminServiceProxy(socket);

        if (isNew) {
            await service.addOfferingAdmin({ 
                offering: { 
                    ...offering, 
                    vendorId: vendorId 
                } 
            });
        } else {
            await service.updateOfferingAdmin({ 
                offering:  { 
                    ...offering, 
                    id: offeringId,
                    vendorId: vendorId 
                }  
            });
        }

        navigate(`/${vendor.code}/services`);
    }

    const onDelete = async () => {
        if (offeringId) {
            const service = getOfferingAdminServiceProxy(socket);

            await service.removeOfferingAdmin({ 
                id: offeringId,
                vendorId: vendorId
            });
        }

        navigate(`/${vendor.code}/services`);
    }

    return (<>
        <h1>{(isNew) ? "New service": "Update"}</h1>
        <div>
            <textarea rows={10} cols={50} value={offeringJson} onChange={evt => setOfferingJson(evt.target.value)} />
            <div>
                <button onClick={onUpdate}>Save</button>

                {(! isNew) ? <button onClick={onDelete}>Delete</button> : undefined }
            </div>            
        </div>
    </>);
}