import { Box, Typography } from "@mui/material";
import BookingCalendar from "./BookingCalendar";
import { useVendorScope } from "./VendorScopeProvider";
import { useEffect, useState } from "react";
import { useSocketIO } from "./SocketIO";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";
import { getStaffBookingServiceProxy } from "../services/StaffBookingServiceProxy";
import dayjs from "dayjs";

type Resource = { resourceId: string; resourceTitle: string };
type Event = {
    id: string;
    title: string;
    start: Date;
    end: Date;
    resourceId: string;
};

const MainContent: React.FC = () => {
    const vendor = useVendorScope();
    const socket = useSocketIO();
    const [resources, setResources] = useState<Resource[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const loadEvents = async (date: Date) => {
        if (!vendor) {
            return;
        }

        try {
            const fromTime = dayjs(date).startOf("day");
            const endTime = dayjs(date).endOf("day");

            const adminService = getStaffAdminServiceProxy(socket);
            const bookingService = getStaffBookingServiceProxy(socket);

            const { staff: staffs } = await adminService.getStaffAdmins({
                vendorId: vendor.id,
            });

            setResources(
                staffs.map((staff) => ({
                    resourceId: staff.id,
                    resourceTitle: `${staff.firstName} ${staff.lastName}`,
                }))
            );

            let foundEvents: Event[] = [];

            for (const staff of staffs) {
                const { booking: bookings } = await bookingService.getBookings({
                    vendorId: vendor.id,
                    staffId: staff.id,
                    fromDateTime: fromTime.toISOString(),
                    toDateTime: endTime.toISOString(),
                });

                const loadedEvents = bookings.map((booking) => ({
                    id: booking.id,
                    resourceId: staff.id,
                    title: booking.offeringName,
                    start: dayjs(booking.startDateTime).toDate(),
                    end: dayjs(booking.endDateTime).toDate(),
                }));

                foundEvents = foundEvents.concat(loadedEvents);
            }

            setEvents(foundEvents);
        } catch (error) {
            console.error("Error loading events:", error);
        }
    };

    useEffect(() => {
        loadEvents(currentDate);
    }, [vendor, socket, currentDate]);

    const onChangeDate = (newDate : Date) => {
        setCurrentDate(newDate);
    }

    return (
        <>
            <Typography variant="h4">Booking Calendar</Typography>
            <Box paddingTop={"10px"}>
                <BookingCalendar
                    events={events}
                    resources={resources}
                    min={new Date(new Date().setHours(9, 0, 0, 0))} // Set start time to 9 AM
                    max={new Date(new Date().setHours(22, 0, 0, 0))} // Set end time to 10 PM
                    onNavigate={onChangeDate} // Hook up the navigate callback
                    currentDate={currentDate} // Pass the current date
                />
            </Box>
        </>
    );
};

export default MainContent;
