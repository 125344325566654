import { Navigate, useNavigate } from "react-router-dom";
import { useVendorScope } from "../components/VendorScopeProvider";
import { useSocketIO } from "../components/SocketIO";
import { staff } from "@anything-pet/grpc-lib";
import { useState } from "react";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";


function getNewStaff() : Omit<staff.Staff, 'id' |'vendorId'> {
    return {
        firstName: '',
        lastName: '',
        emailAddress: '',
        position: ''
    }
}

export function NewStaff() {
    const vendor = useVendorScope();
    const socket = useSocketIO();
    const navigate = useNavigate();
    
    const [staffJson, setStaffJson] = useState<string>(
        JSON.stringify(getNewStaff(), undefined, 2)
        );        
    
    const [message, setMessage] = useState<string>();

    const onUpdate = async () => {
        setMessage(undefined);

        const staff : staff.Staff = JSON.parse(staffJson);

        const service = getStaffAdminServiceProxy(socket);

        const { staff: newStaff } = await service.addStaffAdmin({ 
            staff: { 
                ...staff, 
                vendorId: vendor.id
            } 
        });

        if (newStaff) {
            navigate(`/${vendor.code}/staff/${newStaff.id}`);
        }
    }
    
    return (<>
        <h1>New staff</h1>
        <div>
            <textarea rows={10} cols={50} value={staffJson} onChange={evt => setStaffJson(evt.target.value)} />
            <div>
                <button onClick={onUpdate}>Save</button>
                {message}
            </div>            
        </div>
    </>);
}