import React from "react";
import { Calendar, momentLocalizer, Event, Views } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const localizer = momentLocalizer(moment);

const StyledCalendar = styled("div")(({ theme }) => ({
    height: "1500px",
    "& .rbc-toolbar": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
    },
    "& .rbc-event": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    "& .rbc-month-view": {
        backgroundColor: theme.palette.background.default,
    },
    "& .rbc-time-view": {
        backgroundColor: theme.palette.background.default,
    },
    "& .rbc-agenda-view": {
        backgroundColor: theme.palette.background.default,
    },
    "& .rbc-today": {
        backgroundColor: theme.palette.primary.light,
    },
}));

interface BookingCalendarProps {
    events: Event[];
    resources: { resourceId: string; resourceTitle: string }[];
    min: Date;
    max: Date;
    onNavigate: (newDate: Date) => void;
    currentDate: Date;
}

interface CustomToolbarProps {
    date: Date;
    label: string;
    onNavigate: (newDate: Date) => void;
}

const CustomToolbar: React.FC<CustomToolbarProps & any> = (props) => {
    const { date, label, onNavigate } = props;

    const handleNavigate = (action: "PREV" | "NEXT" | "TODAY") => {
        let newDate: Date;
        if (action === "PREV") {
            newDate = new Date(date);
            newDate.setDate(newDate.getDate() - 1);
        } else if (action === "NEXT") {
            newDate = new Date(date);
            newDate.setDate(newDate.getDate() + 1);
        } else {
            newDate = new Date(); // TODAY
        }

        onNavigate(newDate);
    };

    return (
        <div className="rbc-toolbar">
            <span className="rbc-btn-group">
                <button type="button" onClick={() => handleNavigate("PREV")}>Back</button>
                <button type="button" onClick={() => handleNavigate("TODAY")}>Today</button>
                <button type="button" onClick={() => handleNavigate("NEXT")}>Next</button>
            </span>
            <span className="rbc-toolbar-label">
                <Typography variant="h4" component="span">
                    {label}
                </Typography>
            </span>
        </div>
    );
};

const BookingCalendar: React.FC<BookingCalendarProps> = ({
    events,
    resources,
    min,
    max,
    onNavigate,
    currentDate,
}) => {
    return (
        <StyledCalendar>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                defaultView={Views.DAY}
                views={['day']}
                date={currentDate}  // Use the current date
                resources={resources}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                min={min}  // Set start time
                max={max}  // Set end time
                onNavigate={onNavigate}  // Ensure navigate callback is called
                components={{
                    toolbar: (props: CustomToolbarProps) => <CustomToolbar {...props} onNavigate={onNavigate} />,
                }}
            />
        </StyledCalendar>
    );
};

export default BookingCalendar;
