import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSocketIO } from "../components/SocketIO"
import { staff } from "@anything-pet/grpc-lib";
import { Link } from "react-router-dom";
import { getStaffAdminServiceProxy } from "../services/StaffAdminServiceProxy";
import { useVendorScope } from "../components/VendorScopeProvider";

export function StaffList() {
    const vendor = useVendorScope();

    const { id: vendorId } = vendor;

    const [staffs, setStaffs] = useState<staff.Staff[]>();
    const socket = useSocketIO();

    useEffect(() => {
        async function load() {
            const service = getStaffAdminServiceProxy(socket);

            const result = await service.getStaffAdmins({ vendorId: vendorId });

            setStaffs(result.staff);
        }

        load();
    }, [vendorId]);

    if (! staffs) {
        return <h1>Loading...</h1>;
    }

    return (<>
        <h1>Staff list</h1>
        <Link to={`new`}>New</Link>
        <ul>
            {staffs.map(staff => {
                return (
                    <li key={staff.id}><Link to={`${staff.id}`}>{staff.firstName}, {staff.lastName}</Link></li>
                );
            })}
        </ul>
    </>);
}