import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "@mui/material/styles";

interface QuillEditorProps {
    value: string;
    onChange: (value: string) => void;
}

const QuillEditor: React.FC<QuillEditorProps> = ({ value, onChange }) => {
    const theme = useTheme();

    const handleEditorChange = (content: string) => {
        if (content !== value) {
            onChange(content);
        }
    };

    const editorStyle = {
        //height: '300px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        fontFamily: theme.typography.fontFamily,
        fontSize: `${theme.typography.fontSize}px`,
        color: theme.palette.text.primary,
    };

    return (
        <ReactQuill
            value={value}
            onChange={handleEditorChange}
            modules={{
                toolbar: [
                    //[{ header: '1' }, { header: '2' }, { font: [] }],
                    //[{ size: [] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    //['link', 'image'],
                    ["clean"],
                ],
            }}
            formats={[
                //'header', 'font', 'size',
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "bullet",
                //'link', 'image',
            ]}
            theme="snow"
            style={editorStyle}
        />
    );
};

export default QuillEditor;
