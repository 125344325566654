import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSocketIO } from "../components/SocketIO";
import { offering } from "@anything-pet/grpc-lib";
import { getOfferingAdminServiceProxy } from "../services/OfferingAdminServiceProxy";
import { useVendorScope } from "../components/VendorScopeProvider";
import {
    Button,
    Divider,
    Grid,
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    LinearProgress,
} from "@mui/material";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

export function ListServices() {
    const vendor = useVendorScope();

    if (!vendor) {
        throw new Error("Vendor is not defined");
    }

    const { id: vendorId } = vendor;

    const [offerings, setOfferings] = useState<offering.Offering[]>();
    const socket = useSocketIO();

    useEffect(() => {
        async function load() {
            if (vendorId) {
                const service = getOfferingAdminServiceProxy(socket);

                const result = await service.getOfferingAdmins({
                    vendorId: vendorId,
                });

                setOfferings(result.offering);
            }
        }

        load();
    }, [vendorId]);

    if (!vendorId) {
        return <Navigate to={`/${vendor.code}`} />;
    }

    if (!offerings) {
        return <LinearProgress />;
    }

    const listStyle = {
        py: 0,
        width: "100%",
        borderRadius: 2,
        border: "1px solid",
        borderColor: "divider",
        backgroundColor: "background.paper",
    };

    return (
        <Box>
            <Typography variant="h4">Services</Typography>
            <Typography variant="body1">
                Add at least one service for your business.
            </Typography>
            <Box paddingTop={"20px"} paddingBottom={"20px"}>
                <Button
                    variant="contained"
                    href={`services/new`}
                    startIcon={<AddRoundedIcon />}
                >
                    Add a new service
                </Button>
            </Box>

            <List sx={listStyle}>
                {offerings.map((offering, index) => (
                    <Box key={offering.id}>
                        <ListItem component="div">
                            <Grid container spacing={1} alignItems="center">
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{
                                        textAlign: {
                                            xs: "center",
                                            sm: "left",
                                        },
                                    }}
                                >
                                    <ListItemText
                                        id={`offering-list-item-${offering.id}`}
                                        primary={
                                            <Typography fontWeight="bold">
                                                {offering.name}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{ textAlign: "center" }}
                                >
                                    <ListItemText
                                        id={`offering-list-item-${offering.id}`}
                                        primary={`${offering.duration} mins`}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    sx={{ textAlign: "center" }}
                                >
                                    <ListItemText
                                        id={`offering-list-item-${offering.id}`}
                                        primary={`$${offering.price.toLocaleString(
                                            undefined,
                                            { minimumFractionDigits: 2 }
                                        )}`}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    container
                                    justifyContent={{
                                        xs: "center",
                                        sm: "flex-end",
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        href={`services/${offering.id}`}
                                        startIcon={<CreateRoundedIcon />}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </ListItem>
                        {index < offerings.length - 1 && (
                            <Divider component="li" />
                        )}
                    </Box>
                ))}
            </List>
        </Box>
    );
}
