import { offeringAdmin } from '@anything-pet/grpc-lib'
import { Socket } from 'socket.io-client';
import { emitEvent } from '../components/SocketIO';


/**
 * 
 * @param socket 
 * @returns 
 */
export function getOfferingAdminServiceProxy(socket : Socket) : offeringAdmin.OfferingAdminService {
    return new BffOfferingAdminServiceProxy(socket);
}

/**
 * 
 */
class BffOfferingAdminServiceProxy implements offeringAdmin.OfferingAdminService {
    /**
     * 
     * @param socket 
     */
    constructor(private socket : Socket) { }

    /**
     * 
     * @param request 
     */
    addOfferingAdmin(request: offeringAdmin.AddOfferingAdminRequest): Promise<offeringAdmin.AddOfferingAdminResponse> {
        return emitEvent<offeringAdmin.AddOfferingAdminResponse, offeringAdmin.AddOfferingAdminRequest>(
            this.socket, "offeringAdmin:addOfferingAdmin", request
            );
    }

    /***
     * 
     */
    getOfferingAdmins(request: offeringAdmin.GetOfferingAdminsRequest): Promise<offeringAdmin.GetOfferingAdminsResponse> {
        return emitEvent<offeringAdmin.GetOfferingAdminsResponse, offeringAdmin.GetOfferingAdminsRequest>(
            this.socket, "offeringAdmin:getOfferingAdmins", request
            );    
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    getOfferingAdmin(request: offeringAdmin.GetOfferingAdminRequest): Promise<offeringAdmin.GetOfferingAdminResponse> {
        return emitEvent<offeringAdmin.GetOfferingAdminResponse, offeringAdmin.GetOfferingAdminRequest>(
            this.socket, "offeringAdmin:getOfferingAdmin", request
            );    
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    updateOfferingAdmin(request: offeringAdmin.UpdateOfferingAdminRequest): Promise<offeringAdmin.UpdateOfferingAdminResponse> {
        return emitEvent<offeringAdmin.UpdateOfferingAdminResponse, offeringAdmin.UpdateOfferingAdminRequest>(
            this.socket, "offeringAdmin:updateOfferingAdmin", request
            );    
    }

    /**
     * 
     * @param request 
     * @returns 
     */
    removeOfferingAdmin(request: offeringAdmin.RemoveOfferingAdminRequest): Promise<offeringAdmin.RemoveOfferingAdminResponse> {
        return emitEvent<offeringAdmin.RemoveOfferingAdminResponse, offeringAdmin.RemoveOfferingAdminRequest>(
            this.socket, "offeringAdmin:removeOfferingAdmin", request
            );    
    } 
}