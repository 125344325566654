// theme.ts
import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
    typography: {
        fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
    },
    palette: {
        primary: {
            main: "#FFC000",
            light: "#FFF5DB",
        },
        secondary: {
            main: "#000000",
        },
        background: {
            default: "#ffffff",
            paper: "#ffffff",
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: "#ffffff",
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: "1440px",
                },
            },
        },
    },
});

export default Theme;
