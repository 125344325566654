import React, { useState, useEffect, ChangeEvent } from "react";
import {
    TextField,
    Button,
    Grid,
    Box,
    Typography,
    Tabs,
    Tab,
    LinearProgress,
    Snackbar,
} from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useVendorScope } from "../components/VendorScopeProvider";
import { useSocketIO } from "../components/SocketIO";
import { getVendorAdminServiceProxy } from "../services/VendorAdminServiceProxy";
import { vendorAdmin } from "@anything-pet/grpc-lib";
import { useNavigate } from "react-router-dom";
import QuillEditor from "../components/QuillEditor";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditProfile: React.FC = () => {
    const [data, setData] = useState<vendorAdmin.VendorAdmin | undefined>();
    const [tabIndex, setTabIndex] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const socket = useSocketIO();
    const navigate = useNavigate();
    const vendor = useVendorScope();
    const vendorId = vendor?.id;

    useEffect(() => {
        async function load() {
            if (vendorId) {
                const service = getVendorAdminServiceProxy(socket);
                const result = await service.getVendorAdmin({ id: vendorId });
                if (result.vendor) {
                    const { id, ...vendorParam } = result.vendor;
                    setData(
                        JSON.parse(JSON.stringify(vendorParam, undefined, 2))
                    );
                }
            }
        }

        load();
    }, [vendorId, socket]);

    if (!vendorId) {
        throw new Error("Not in vendor scope");
    }

    if (!data) {
        return <LinearProgress />;
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const handleOfficeHoursChange = (
        day: keyof vendorAdmin.OfficeHours,
        field: keyof vendorAdmin.OfficeDayHours,
        value: string
    ) => {
        const newData: vendorAdmin.VendorAdmin = {
            ...data,
        };

        newData.officeHours = newData.officeHours || {
            monday: undefined,
            tuesday: undefined,
            wednesday: undefined,
            thursday: undefined,
            friday: undefined,
            saturday: undefined,
            sunday: undefined,
        };

        const officeHour = newData.officeHours[day] ?? {
            startTime: "",
            endTime: "",
        };
        newData.officeHours[day] = officeHour;

        officeHour[field] = value;

        setData(newData);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!data) return;

        const vendor: vendorAdmin.VendorAdmin = {
            ...data,
            id: vendorId,
            officeHours: {
                sunday: data.officeHours?.sunday ?? undefined,
                monday: data.officeHours?.monday ?? undefined,
                tuesday: data.officeHours?.tuesday ?? undefined,
                wednesday: data.officeHours?.wednesday ?? undefined,
                thursday: data.officeHours?.thursday ?? undefined,
                friday: data.officeHours?.friday ?? undefined,
                saturday: data.officeHours?.saturday ?? undefined,
            },
        };

        const service = getVendorAdminServiceProxy(socket);

        try {
            await service.updateVendorAdmin({ vendor });
            console.log("Vendor updated successfully:", data);
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Error updating vendor:", error);
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
        setTabIndex(newIndex);
    };

    const handleCancellationPolicyChange = (value: string) => {
        setData({ ...data, bookingCancellationPolicy: value });
    };

    const handleAdditionalBookingNotesChange = (value: string) => {
        setData({ ...data, additionalBookingNotes: value });
    };

    const handleAboutChange = (value: string) => {
        setData({ ...data, about: value });
    };

    const handleAdditionalInfoChange = (value: string) => {
        setData({ ...data, additionalInfo: value });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Typography variant="h4">Business Profile</Typography>
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="Edit Profile Tabs"
            >
                <Tab label="Business Name and Address" />
                <Tab label="Office Hours" />
                <Tab label="Booking Communication" />
            </Tabs>
            {tabIndex === 0 && (
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Business Name"
                                name="name"
                                value={data.name}
                                onChange={handleInputChange}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Business ID"
                                name="code"
                                value={data.code}
                                onChange={handleInputChange}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Location or neighbourhood"
                                name="location"
                                value={data.location}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Address"
                                name="address"
                                value={data.address}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" paddingBottom={"10px"}>
                                About
                            </Typography>
                            <QuillEditor
                                value={data.about}
                                onChange={handleAboutChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" paddingBottom={"10px"}>
                                Additional Info
                            </Typography>
                            <QuillEditor
                                value={data.additionalInfo}
                                onChange={handleAdditionalInfoChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Google Map Source"
                                name="googleMapSrc"
                                value={data.googleMapSrc}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}
            {tabIndex === 1 && (
                <Box sx={{ p: 3 }}>
                    <Typography variant="body1" paddingBottom={"10px"}>
                        Please enter the time in 24-hour format (hh:mm). If you
                        are closed on the day, just leave the field empty.
                    </Typography>
                    <Box paddingBottom={"20px"}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Time Zone"
                                name="timeZone"
                                value={data.timeZone}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Box>
                    <Grid container spacing={2}>
                        {Object.entries(data.officeHours || {}).map(
                            ([day, hours]) => (
                                <React.Fragment key={day}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label={`${
                                                day.charAt(0).toUpperCase() +
                                                day.slice(1)
                                            } Start Time`}
                                            name={`${day}StartTime`}
                                            value={hours?.startTime || ""}
                                            onChange={(e) =>
                                                handleOfficeHoursChange(
                                                    day as keyof vendorAdmin.OfficeHours,
                                                    "startTime",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label={`${
                                                day.charAt(0).toUpperCase() +
                                                day.slice(1)
                                            } End Time`}
                                            name={`${day}EndTime`}
                                            value={hours?.endTime || ""}
                                            onChange={(e) =>
                                                handleOfficeHoursChange(
                                                    day as keyof vendorAdmin.OfficeHours,
                                                    "endTime",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Grid>
                                </React.Fragment>
                            )
                        )}
                    </Grid>
                </Box>
            )}
            {tabIndex === 2 && (
                <Box sx={{ p: 3 }}>
                    <Typography variant="body1" paddingBottom={"10px"}>
                        Optionally, enter the following to be displayed in the
                        booking confirmation email.
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" paddingBottom={"10px"}>
                                Booking Cancellation Policy
                            </Typography>
                            <QuillEditor
                                value={data.bookingCancellationPolicy}
                                onChange={handleCancellationPolicyChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" paddingBottom={"10px"}>
                                Additional Booking Notes
                            </Typography>
                            <QuillEditor
                                value={data.additionalBookingNotes}
                                onChange={handleAdditionalBookingNotesChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}

            <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
            >
                Save
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{ width: "100%" }}
                >
                    Profile saved successfully!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default EditProfile;
